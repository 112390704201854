import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/.pnpm/next@15.1.7_react-dom@19.0.0_react@19.0.0__react@19.0.0_sass@1.77.4/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/app/src/app/(frontend)/components/header.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/(frontend)/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["CanonicalLink"] */ "/app/src/components/CanonicalLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/context/Store.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/app/src/context/ThemeProvider.tsx");
